import React from 'react';
import { api } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { FiCalendar, FiClock } from "react-icons/fi";
export default function CardFormation({ item }) {
    const navigate = useNavigate()
    function go() {

        
            navigate('/Formation/Details/'+item.id)
        
    }
    return (
        <>
            <div
                onClick={() => {
                    go();
                }}
                className="flex flex-col w-full h-[380px] bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl cursor-pointer"
            >
                {/* Image */}
                <div className="relative">
                    <img
                        src={api + "/storage/" + item.photo}
                        alt="photo"
                        className="w-full max-w-full h-[250px] object-cover"
                    />
                </div>

                {/* Contenu */}
                <div className="p-4 flex flex-col gap-2">
                    {/* Titre */}
                    <h4 className="text-[#2C2F24] font-semibold text-xl text-start">
                        {item.titre}
                    </h4>

                    {/* Description */}
                    <p className="text-[#737865] text-lg leading-relaxed line-clamp-2 text-start">
                        {item.description}
                    </p>

                    {/* Informations complémentaires */}
                    <div className="flex flex-row justify-between text-[#737865] text-[12px] font-medium border-t pt-2 mt-2">
                        <span className="flex items-center gap-1">
                            <FiCalendar className="text-[#737865]" />
                            {item.dateDebut}
                        </span>
                        <span className="flex items-center gap-1">
                            <FiClock className="text-[#737865]" />
                            {item.duree}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}