

// import Routers from './Routers'

// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LandingPage from './entrepreneur/pages/LandingPage'
// import Layout from './admin/layout/Layout'
import Layout from './entrepreneur/Layout';
import Auth from './entrepreneur/Layout/(auth)';
import Signup from './Signup';
import Login from './login'
import Formation from './entrepreneur/pages/Formation';
import Profile from './entrepreneur/pages/Profile';
import ABFLayout from './entrepreneur/Layout/(ABF)';
import About from './entrepreneur/pages/About'
import ABF from './entrepreneur/pages/ABF';
import Dashboard from './admin/pages/Dashboard/Dashboard'
import LayoutF from './admin/layout/Layout'
import FormationF from './admin/pages/Formation/Formation'
import AjouterFormation from './admin/pages/Formation/AjouterFormation'
import DetailFormation from './admin/pages/Formation/ModifierFormation'
import Inscription from './admin/pages/Inscription/Inscription'
import Entrepreneur from './admin/pages/Entrepreneur.jsx/Entrepreneur'
import DetailEntrepreneur from './admin/pages/Entrepreneur.jsx/DetailEntrepreneur'
import { DetailInscription } from './admin/pages/Inscription/DetailInscription'
import ConsulterFormation from './admin/pages/Formation/ConsulterFormation'
import Parametre from './admin/pages/Parametre/Parametre'
import Rapport from './admin/pages/Rapport/RapportHTML2PDF'
import Fiche from './admin/pages/Rapport/Fiche';
import ForgotPassword from './ForgotPassword';
import DetailsFormation from './entrepreneur/pages/Formation/Details';
// import ErrorPage from './error';


function App() {
  return (

    <BrowserRouter>
      <Routes>


        <Route path='/' element={<Layout />}>
          <Route path='/Login' element={<Login />} />
          <Route path="/Signup" element={<Auth />}>
            <Route index element={<Signup />} />
          </Route>
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route index element={<LandingPage />} />
          <Route path='Formation' element={<Formation />} />
          <Route path='Formation/Details/:id' element={<DetailsFormation />} />
          <Route path='Profile' element={<Profile />} />
          <Route path='/ABF/:id' element={<ABFLayout />}>
            <Route index element={<ABF />} />
          </Route>
          <Route path="apropos" element={<About />} />
        </Route>
        <Route path="/formateur" element={<LayoutF />}>
          <Route index element={<Dashboard />} />
          <Route path="formation" element={<FormationF />} />
          <Route path="formation/new" element={<AjouterFormation />} />
          <Route path="formation/:id" element={<DetailFormation />} />
          <Route path="formation/view/:id" element={<ConsulterFormation />} />
          <Route path="inscription" element={<Inscription />} />
          <Route path="inscription/:id" element={<DetailInscription />} />
          <Route path="entrepreneur" element={<Entrepreneur />} />
          <Route path="entrepreneur/:id" element={<DetailEntrepreneur />} />
          <Route path="parametre" element={<Parametre />} />
          <Route path="rapport/:id" element={<Rapport />} />
          <Route path="fiche" element={<Fiche />} />
        </Route>


        {/* </Routes> */}

        <Route path="/formateur" element={<LayoutF />}>
          <Route index element={<Dashboard />} />
          <Route path="formation" element={<FormationF />} />
          <Route path="formation/new" element={<AjouterFormation />} />
          <Route path="formation/:id" element={<DetailFormation />} />
          <Route path="formation/view/:id" element={<ConsulterFormation />} />
          <Route path="inscription" element={<Inscription />} />
          <Route path="inscription/:id" element={<DetailInscription />} />
          <Route path="entrepreneur" element={<Entrepreneur />} />
          <Route path="entrepreneur/:id" element={<DetailEntrepreneur />} />
          <Route path="parametre" element={<Parametre />} />
          <Route path="rapport/:id" element={<Rapport />} />

        </Route>
        {/* <Route path="/Signup" element={<Auth />}>
          <Route index element={<Signup />} />
        </Route> */}





      </Routes>
    </BrowserRouter>

  );

}

export default App;
