import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import { FiCalendar, FiClock } from 'react-icons/fi'
import Button from '../../../components/btn/Button'
import Modals from '../../../components/modal'

export default function DetailsFormation() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [isOpen, setIsOpen] = useState({
        image: false
    })
    function go() {

        const id = localStorage.getItem('naturagerme-id')
        if (id && data?.id) {
            navigate("/ABF/" + data?.id)
        } else {
            navigate("/Login")
        }
    }
    function fetchData() {
        axios.get(api + "/api/getDetailFormation/" + id)
            .then(res => {
                console.log(res.data)
                setData(res.data[0])
            })
            .catch(err => {
                console.error(err)
            })
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <>
        <div className='w-11/12 flex flex-row max-md:flex-col gap-5'>
            <img alt='formation' src={api + "/storage/" + data?.photo} className='w-[400px] max-md:w-full' onClick={() => { setIsOpen({ ...isOpen, image: true }) }} />
            <div className='w-full flex flex-col gap-y-3'>
                <h4>{data?.titre}</h4>
                <p>{data?.description}</p>
                <p>Lieu : {data?.lieu}</p>
                <hr />
                <div className="flex flex-row justify-start gap-x-12 text-[#737865] text-[12px] font-medium  ">
                    <span className="flex items-center gap-1">
                        <FiCalendar className="text-[#737865]" />
                        {data?.dateDebut + " au " + data?.dateFin}
                    </span>
                    <span className="flex items-center gap-1">
                        <FiClock className="text-[#737865]" />
                        {data?.duree}
                    </span>
                </div>
                <div className='flex flex-row md:justify-end items-center '>
                    <button className="max-lg:w-full min-w-[200px] bg-slate-900 text-white h-10 font-semibold" onClick={go}>Participer</button>
                </div>
            </div>
            <Modals isOpen={isOpen.image} onClose={() => {
                setIsOpen({ ...isOpen, image: false })
            }}>
                <img alt='formation' src={api + "/storage/" + data?.photo} className='w-full' />

            </Modals>
        </div>
        </>
    )
}
